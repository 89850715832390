<template>
<div class="widget-container">
    <article>
        <link :href="chunkCss" rel="stylesheet">
        <link :href="appCss" rel="stylesheet">
        <div
        class="smartcoach-app"
        ref="smartcoachProWidget"
        :data-authentication-token="widget.token"
        :data-alerts-url="alertsUrl"
        :data-lang="this.$store.state.lang.lang"
        data-questionnaire-url="__url_of_questionnaire_page__"
        :data-housingNb="widget.housingNb"
        :data-employeeNb="widget.employeeNb"
        :data-area="widget.area" 
        data-info-block=1
        >
        </div>
    </article>
</div>
</template>


<script>
    export default {
        
        props: {
            widget: {required: true},
            widgetParams: {required: false}
        },

        data: function(){
            return {
                chunkCss: '',
                appCss: '',
                alertsUrl: ''
            }
        },

        mounted() {

            var smartcoachProWidget = this.$refs.smartcoachProWidget;

            for (const property in this.widgetParams) {
                smartcoachProWidget.setAttribute('data-' + `${property}`, `${this.widgetParams[property]}`);
            }
            
            let vendorScript = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/chunk-vendors.js';

            let appScript = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/app.js';


            this.$unloadScript(vendorScript)
            .then(() => {
                this.$loadScript(vendorScript);
            })
            .catch(() => {
                this.$loadScript(vendorScript);
            });

            this.$unloadScript(appScript)
            .then(() => {
                this.$loadScript(appScript);
            })
            .catch(() => {
                this.$loadScript(appScript);
            });

            this.chunkCss = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/chunk-vendors.css';
            this.appCss = this.widget.widgetUrlPrefix + '/' + this.widget.version + '/app.css';

            const alertsUrl = this.$router.resolve({ name: 'alerts', params: { client: this.$store.state.user.client.name }});
            this.alertsUrl = alertsUrl.href;
        }
        
    }
</script>