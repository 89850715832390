<template>
    <header class="main-header" :style="myBgColorStyle">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-xs-2 col-md-2 header-controls">
                    <div class="header-item-left header-item-left-menu">
                        <div class="menu-img">
                            <img v-bind:src="this.$store.state.user.client.logo" width='60%' />
                        </div>
                    </div>
                </div>
                <div class="col-xs-6 col-md-10 header-links">
                    <div class="hidden-xs">
                        <div class="header-item menu-header-separator">
                            <router-link to="/logout">
                                <span :style="myTxtColorStyle">{{ this.$i18n.t('logout') }} <img height="20" src="../../assets/images/icons/logout.png" /></span>
                                   
                            </router-link>
                        </div>
                        <div class="header-item">
                            <langSwitcher></langSwitcher>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </header> 
</template>


<script>

import langSwitcher from "../functionnals/langSwitcher";
import Configs from "../../../configs";

export default {
    components:{
        langSwitcher
      },

    computed: {
        myBgColorStyle : function(){
            return {
                backgroundColor: '#'+this.$store.state.user.template.headerBgColor
            }
        },

        myTxtColorStyle: function () {
            return {
                color: '#'+this.$store.state.user.template.headerTextColor
            }
        },

        logoSrc: function () {
            
            if(this.$store.state.user.client.logo){
                return Configs.value('VUE_APP_RESOURCES_URL') + '/' + this.$store.state.user.client.logo
            }
            return null;
        }
    },

    methods: {
        goMenu: function() {
            this.$store.dispatch('goMenu');
        }
    }
}

</script>
