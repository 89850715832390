<template>

  <div class="vertical-drop-down-container" :class="[serviceName =='SmartCoachPro' ? 'custom-bg margin-top-20' : '']">
    <div class="subscription-name" :style="styleObject" v-if="subscription && serviceName == 'SmartCoachPro'">
        <p class="title" v-if="subscription.pdsname">{{subscription.pdsname}}</p>
        <p class="title" v-else-if="!subscription.pdsname && this.$store.state.user.subscriptions.length == 1">{{ this.$i18n.t('_your_counter') }}</p>
        <p class="title" v-else-if="!subscription.pdsname && this.$store.state.user.subscriptions.length > 1">{{ this.$i18n.t('_your_site') }}</p>
        <p class='subtitle'><span>{{subscription.pds}} - </span>{{utils.formatDate(subscription.creationDate)}}</p>
    </div>

    <div class="drop-down-container">
            <ul class="nav-bar">
              <router-link :to="type == 'subscriptions' ? { name: 'subscriptions'} : { name: 'subscription', params: { client: this.$store.state.user.client.name, subscriptionId: subscription.id }}"> 
                <li class="nav-item consumption-details-menu nav-item-border-right" :class="{ 'active' : type == 'subscriptions' }">
                    <a> {{ $t('_consumption_details') }} </a>
                </li>
              </router-link>
              <router-link :to="type=='profile' ? { name: 'subscriptions'} : { name: 'profile', params: { client: this.$store.state.user.client.name, subscriptionId: subscription.id }}">   
                  <li class="nav-item profil-configured-menu" :class="{ 'active' : type == 'profile' }">
                    <a > {{ $t('_profile') }} : 
                            <span v-if="!subscription.completed">{{ $t('_not_configured') }}</span>
                            <span v-else-if="subscription.completed">{{ $t('_configured') }}</span>
                        </a>
                </li>  
              </router-link>
            </ul>
    </div>
   

    
  </div>
</template>

<script>
  import Utils from "../../utils/Util";
  export default {
    name: 'ProfileWidgetLink',
    
    props: {
        type: {type: String, required: true},
        subscription: {type: Object, required: true}
    },

    data() {
      return {
            utils: new Utils(),
            styleObject: {
                borderColor: (this.subscription.color ? this.subscription.color : '#000'),
            },
            serviceName: this.$store.state.user.service_client.name
      }
    },

    computed: {
        },

    methods: {

    }
  }
</script>