<template>
<div class="menu-container bold" :class="[!this.$store.state.menuOpen ? 'menu-collapsed' : '']" :style="sideBarBgStyle">

    <div class='menu-top' v-if="this.$store.state.user.cguAcceptation && this.$store.state.user.service_client != null">
        <div class="menu-top-collapse-btn" @click="goMenu()"> 
            <img v-if="this.$store.state.menuOpen" src="../../assets/images/icons/menu-left.png"> 
            <img v-else src="../../assets/images/icons/menu-right.png"> 
        </div>
    </div>

    <template v-if="this.$store.state.user.cguAcceptation && this.$store.state.user.service_client != null">

        <router-link :to="{ name: 'home', params: { client: client }}" :class="[this.$route.meta.global == 'home' ? 'router-link-active' : '' ]">
            <div class="home-item menu-label" :class="[!this.$store.state.menuOpen ? 'menu-label-hide' : '']" :style="sideBarTxtStyle"> <span>{{this.$i18n.t('_board')}}</span> </div>
        </router-link>

        <router-link v-if="this.$store.state.user.subscriptions.length > 1" :to="{ name:'subscriptions' }" :class="[this.$route.meta.global == 'subscriptions' ? 'router-link-active' : '' ]">
            <div class="menu-label subscription-item" :class="[!this.$store.state.menuOpen ? 'menu-label-hide' : '']" :style="sideBarTxtStyle"> <span>{{this.$i18n.t('_my_consumption')}} </span></div>
        </router-link>
        <router-link v-else :to="{ name:'subscription' , params: { client: client, subscriptionId: this.$store.state.user.subscriptions[0].id }}" :class="[this.$route.meta.global == 'subscriptions' ? 'router-link-active' : '' ]">
            <div class="menu-label subscription-item" :class="[!this.$store.state.menuOpen ? 'menu-label-hide' : '']" :style="sideBarTxtStyle"> <span>{{this.$i18n.t('_my_consumption')}}</span> </div>
        </router-link>

        <template v-if="externalWidgets.widgets">
            <template v-for="(widget, index) in getExternalWidgets">
                <router-link :key="index" v-if="externalWidgets.widgets.includes('widgetAlarm') && widget.name == 'widgetAlarm'" :to="{ name:'alerts' , params: { client: client }}" :class="[$route.meta.global == 'alerts' ? 'router-link-active' : '' ]">
                    <div class="menu-label alerts-item" :class="[!$store.state.menuOpen ? 'menu-label-hide' : '']" :style="sideBarTxtStyle"> <span>{{$i18n.t('_my_alerts')}}</span> </div>
                </router-link>
            </template>
        </template>
        
        <router-link :to="{ name: 'personalInformations', params: { client: client }}" :class="[this.$route.meta.global == 'personalInfos' ? 'router-link-active' : '' ]"> 
            <div class="menu-label information-item" :class="[!this.$store.state.menuOpen ? 'menu-label-hide' : '']" :style="sideBarTxtStyle"> <a><span>{{this.$i18n.t('_my_personal_informations')}}</span></a> </div>
        </router-link>

        <template v-for="(page, index) in pages" >
            <router-link :key="index" :to="{ name:'singlePage', params: { client: client, pageId: page.id }}">
                <div class="menu-label page-item" :class="[!appStore.menuOpen ? 'menu-label-hide' : '']" :style="sideBarTxtStyle"> <a><span>{{page.title}}</span></a> </div>
            </router-link>
        </template>

        <router-link :to="{ name: 'contact', params: { client: client }}" :class="[this.$route.meta.global == 'contact' ? 'router-link-active' : '' ]">
            <div class="menu-label contact-item" :class="[!this.$store.state.menuOpen ? 'menu-label-hide' : '']" :style="sideBarTxtStyle"> <span>{{this.$i18n.t('_contact')}}</span> </div>
        </router-link>
            
    </template>

    
</div>
</template>

<script>
import {mapGetters} from "vuex";
import externalWidgets from "../../utils/externalWidgets";

export default {

    props:{
        subscriptions: {type: Array},
        services: {type: Array},
        pages: {type: Array},
        currentPage: {type: String},
        
    },
    


    mounted() {
        this.$store.dispatch('fetchUser');
        const user = this.$store.state.user;
        this.client = user.client.name;
        this.$store.dispatch('initializeSideBare', localStorage.getItem('sideBareState'));
    
        //
    },


    data: function(){
        return {
            client: "",
            appStore: this.$store.state,
            externalWidgets: externalWidgets
        }
    },

    methods: {

        goMenu: function() {
            localStorage.setItem('sideBareState', !this.$store.state.menuOpen);
            this.$store.dispatch('goMenu');
        }
        
    },

   

    computed: {
        sideBarBgStyle : function(){
            return {
                backgroundColor: '#'+this.$store.state.user.template.sideBarBgColor,

            }
        },
        sideBarTxtStyle : function(){
            console.log(this.$store.state.user.template.menuTitleBackgroudColor)
            return {
               color: '#'+ this.$store.state.user.template.sideBarTextColor,
               '--color-hover': (this.$store.state.user.template.menuTitleBackgroudColor == null) ? "#"+ this.$store.state.user.template.menuTitleBackgroudColor : '#9bcd41' //9bcd41
            }
        },
        ...mapGetters([
          'getSubscriptionsWithoutConsents',
          'getExternalWidgets'
        ]),
      },

}

</script>


